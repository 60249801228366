import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import set from 'lodash/set';
import hash from 'json-stable-stringify';
import GateToggle from 'components/admin-bridge/Toolbar/actions/GateToggle';
import AutoSave from 'components/admin-bridge/Toolbar/actions/AutoSave';
import ToolbarButton from 'components/admin-bridge/Toolbar/actions/Button';
import ToolbarPortal from 'components/admin-bridge/Toolbar/Portal';
import ChannelSelector from 'components/ui/ChannelSelector';
import DocumentTitle from 'components/application/Site/DocumentTitle';
import { UploadZoneWithoutPadding, IMAGE_ACCEPTED_TYPES } from 'components/admin2/UploadZone';
import type IObject from 'models/IObject';
import useValidator from 'hooks/use-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getPath } from 'services/app-router/selectors/common';
import { setObject } from 'services/app/actions';
import { isLandingPageType } from 'services/app/selectors';
import { pageTypes } from 'services/app/constants';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics';
import DeletePageButton from './DeletePageButton';
import ChannelNameFields from './ChannelNameFields';
import SEOFields from './SEOFields';
import LocaleDropdown from './LocaleDropdown';
import { getObject } from 'services/app/selectors';
import { setActiveAction, clearEditTarget, disablePanels } from 'services/admin/actions';
import { isEditMode } from 'services/admin';
import EditChannelPage from 'components/admin-bridge/Toolbar/actions/EditChannelPage';
import { getAdminAlertWarning } from 'services/themes';
import { useLocalizedPageOrChannelTitle } from 'hooks/localization/useLocalizedPageOrChannelTitle';
import { default as useFeatureGateEnabled, Feature } from 'hooks/use-feature-gate-enabled';
import AdsConfigTab from '../AdsConfigTab';
import PageVisibilityTab from '../PageVisibilityTab';
import { updatePageInNavigations } from 'services/navigationv2';

const paths = {
  CHANNEL_NAME: 'data.name',
  DESCRIPTION: 'seo.description',
  KEYWORDS: 'seo.keywords',
  LANDING: 'data.landingContent',
  NAV_IMG: 'data.channelSelectActiveImageUrl',
  PAGE_TITLE: 'seo.title',
  SEO_IMG: 'seo.image',
  SEO_IMG_SAME_AS_CARD: 'seo.isImageSameAsCard',
  PRIVATE: 'data.private',
};

interface IPageEditorProps {
  canPublish: boolean;
  docExists: boolean;
  initialDoc: IObject;
  onDelete?: (() => void) | null;
  onPublish: () => void;
  onSave: (doc: IObject) => void;
}

export default function PageEditor({
  canPublish,
  docExists,
  initialDoc,
  onDelete,
  onPublish,
  onSave,
}: IPageEditorProps) {
  const dispatch = useDispatch();
  const adminAlertWarning = useSelector(getAdminAlertWarning);
  const publishedDoc = useSelector(getObject);
  const inEditMode = useSelector(isEditMode);
  const [doc, setDoc] = useState(initialDoc);
  const isLandingPage = useSelector(isLandingPageType);
  const nameOrNull = doc.data?.name;
  const name = typeof nameOrNull === 'string' ? nameOrNull : `Untitled ${isLandingPage ? 'page' : 'channel'}`;
  const pathname = useSelector(getPath);
  const [firstPublish, setFirstPublish] = useState(!docExists);
  const [, pageTitle] = useLocalizedPageOrChannelTitle();

  const imageSEO = doc.seo?.image || null;
  const imageCard = doc.data?.channelSelectActiveImageUrl || null;
  const isSEOImageSameAsCard = doc.seo?.isImageSameAsCard ?? (imageSEO ? false : true);

  const pageAlreadyCreated = useMemo(() => Boolean(doc.created), [doc]);
  const pageNameAndSeoTitleMatch = useMemo(
    () => Boolean(publishedDoc.data.name === publishedDoc.seo?.title),
    [hash(publishedDoc)],
  );

  const { error: isSlugError, fetching: isSlugFetching, valid: isSlugValid } = useValidator({
    collection: 'object',
    doc: {
      collection: 'pages',
      site_id: doc.siteId,
      slug: doc.slug,
    },
    enabled: publishedDoc.created ? publishedDoc.slug !== doc.slug : true,
  });

  const isAdsFFOn = useFeatureGateEnabled({
    feature: Feature.ADS,
    type: 'feature',
  });

  useEffect(() => {
    if (hash(doc) !== hash(initialDoc)) {
      setDoc(initialDoc);
    }
  }, [hash(initialDoc)]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (hash(doc) !== hash(initialDoc) && isSlugValid) {
        onSave(doc);
      }
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [doc, isSlugValid]);

  const trackPagePublish = () => {
    dispatch(
      trackEvent({
        event: doc.type === pageTypes.LANDING ?
          AdminActionEvents.PAGE_PUBLISHED : AdminActionEvents.CHANNEL_PUBLISHED,
        properties: {
          channelId: doc._id,
          channelName: name,
          newPage: !pageAlreadyCreated,
        },
      }),
    );
  };

  const onFirstPublish = () => {
    if (firstPublish) {
      dispatch(setObject(doc, true));
      setFirstPublish(false);
    }
  };

  const updateNavigations = () => {
    const shouldUpdateNavigations = doc.slug !== publishedDoc.slug || doc.data.name !== publishedDoc.data.name || doc.data.private !== publishedDoc.data.private;
    if (shouldUpdateNavigations) {
      dispatch(updatePageInNavigations(doc._id, doc.data.name!, doc.slug, doc.data.private));
    }
  };

  const publish = () => {
    onPublish();
    trackPagePublish();
    onFirstPublish();
    updateNavigations();
    exitEditor();
  };

  const updatePageName = (newName: string) => {
    setDoc(produce((draft) => {
      set(draft, paths.CHANNEL_NAME, newName);
      if (pageNameAndSeoTitleMatch) set(draft, paths.PAGE_TITLE, newName);
      return draft;
    }));
  };

  const updatePageSlug = (slug: string) => {
    setDoc(prevDoc => ({ ...prevDoc, slug }));
  };

  const clearCardImage = () => {
    setDoc(produce((draft) => set(draft, paths.NAV_IMG, null)));
  };

  const submitCardImage = (url: string) => {
    setDoc(produce((draft) => set(draft, paths.NAV_IMG, url)));
  };

  const updateSEOTitle = (title: string) => {
    setDoc(produce((draft) => set(draft, paths.PAGE_TITLE, title)));
  };

  const updatePageVisibility = (isPrivate: boolean) => {
    setDoc(produce((draft) => set(draft, paths.PRIVATE, isPrivate)));
  };

  const updateSEODescription = (description: string) => {
    setDoc(produce((draft) => set(draft, paths.DESCRIPTION, description)));
  };

  const updateSEOKeywords = (keywords: string) => {
    setDoc(produce((draft) => set(draft, paths.KEYWORDS, keywords)));
  };

  const clearSEOImage = () => {
    setDoc(produce((draft) => set(draft, paths.SEO_IMG, null)));
  };

  const submitSEOImage = (url: string) => {
    setDoc(produce((draft) => set(draft, paths.SEO_IMG, url)));
  };

  const updateIsSEOImageSameAsCard = (isImageSameAsCard: boolean) => {
    setDoc(produce((draft) => set(draft, paths.SEO_IMG_SAME_AS_CARD, isImageSameAsCard)));
  };

  const exitEditor = () => {
    dispatch(setActiveAction(null));
    dispatch(disablePanels());
    dispatch(clearEditTarget());
  };

  return (
    <PageEditorContainer>
      <DocumentTitle title={pageTitle} />
      <ToolbarPortal>
        <GateToggle shouldRender={true} />
        <AutoSave shouldRender={true} />
        {inEditMode ? (
          <ToolbarButton
            data-testid="exitEditorButton"
            onClick={exitEditor}
            shouldRender={true}
            stringKey="ADMIN_ACTION_EXIT_EDITOR"
          />
        ) : (
          <EditChannelPage
            shouldRender={true}
          />
        )}
        <ToolbarButton
          background={adminAlertWarning}
          data-testid="publishChannelButton"
          disabled={!canPublish || !isSlugValid}
          onClick={publish}
          stringKey="ADMIN_ACTION_PUBLISH"
        />
      </ToolbarPortal>
      <ChannelNameFields
        isSlugError={isSlugError}
        isSlugFetching={isSlugFetching}
        isSlugValid={isSlugValid}
        name={name}
        onNameChange={updatePageName}
        onSlugChange={updatePageSlug}
        slug={doc.slug}
      />
      <PageCardUploadZone
        descriptionKey={`ADMIN_LABEL_${isLandingPage ? 'PAGE' : 'CHANNEL'}_CARD_UPLOAD_DESCRIPTION`}
        imagePreview={imageCard}
        labelKey="ADMIN_LABEL_THUMBNAIL"
        nodeEmptyPlaceholder={(
          <ChannelSelector
            noLink={true}
            page={doc}
          />
        )}
        onClearImage={clearCardImage}
        onFileSubmit={submitCardImage}
        testIdThumbnail="channelThumbnail"
      />
      <PageVisibilityTab updatePageVisibility={updatePageVisibility} />
      {isAdsFFOn && (
        <AdsConfigTab pageLevel={true} />
      )}
      <LocaleDropdown />
      <SEOFields
        description={doc.seo?.description || ''}
        disableSameAsCardToggle={!doc.data?.channelSelectActiveImageUrl}
        imagePreview={imageSEO}
        isImageSameAsCard={isSEOImageSameAsCard}
        isLandingPage={isLandingPage}
        keywords={doc.seo?.keywords || ''}
        title={doc.seo?.title || ''}
        onClearImage={clearSEOImage}
        onDescriptionChange={updateSEODescription}
        onFileSubmit={submitSEOImage}
        onKeywordsChange={updateSEOKeywords}
        onSameAsCardChange={updateIsSEOImageSameAsCard}
        onTitleChange={updateSEOTitle}
      />
      <DeletePageButton onDelete={onDelete} />
    </PageEditorContainer>
  );
}

const PageEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PageCardUploadZone = styled(UploadZoneWithoutPadding).attrs(() => ({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    exactDimensions: true,
    pixelHeight: 640,
    pixelWidth: 360,
  },
  height: 172,
  withWrapper: true,
}))``;
